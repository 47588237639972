<template>
  <div class="app-container">
    <div id="app1">
      <div class="content">
        <div class="detail">
          <div class="box">
            <span class="label">客户名称：</span>
            <div class="text">{{ cname }}</div>
          </div>
          <div class="box">
            <span class="label">门店名称：</span>
            <div class="text">{{ shopName }}</div>
          </div>
          <div class="box">
            <span class="label">端口号：</span>
            <div class="text">{{ boxPort }}</div>
          </div>
          <div class="box">
            <span class="label">SolutionID：</span>
            <div class="text">{{ productId }}</div>
          </div>
          <div class="box">
            <span class="label">盒子Mac-ID：</span>
            <div class="text">{{ boxMacId }}</div>
          </div>
          <div class="box">
            <span class="label">状态：</span>
            <div class="text">{{ status }}</div>
          </div>
        </div>
        <div class="title">
          <span>{{ boxNo }}</span>
          -已绑定摄像头
        </div>

        <el-table :data="bindTableData" class="bindtable" v-loading="bindLoading" show-overflow-tooltip>
          <el-table-column type="index" :index="indexTable" min-width="30" label="序号" width="80"></el-table-column>
          <el-table-column prop="cameraId" align="center" label="CameraID" min-width="150"></el-table-column>
          <el-table-column prop="cameraNo" align="left" label="摄像头名称" width="80"></el-table-column>
          <el-table-column prop="streamSource" align="left" label="拉流方式" width="80"></el-table-column>
          <el-table-column prop="cameraNo" align="center" label="状态" width="80">
            <template slot-scope="scope">
              {{ scope.row.online === true ? '在线' : '离线' }}
            </template>
          </el-table-column>
          <el-table-column prop="cameraNo" align="center" label="硬编解码" width="80">
            <template slot-scope="scope">
              {{ scope.row.accelerateHardware === true ? '开启' : '关闭' }}
            </template>
          </el-table-column>
          <el-table-column prop="cameraNo" align="center" label="码率" width="80">
            <template slot="header">
              <span>码率</span>
              <el-tooltip effect="light" content="营业时间/非营业时间">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              {{
                scope.row.accelerateHardware && scope.row.hardVideo
                  ? computedDataRate(scope.row.hardVideo?.dataRateUpperLimit) +
                    '/' +
                    computedDataRate(scope.row.hardVideo?.closedtimeBitrate)
                  : '--'
              }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="productId" align="center" label="SolutionID" width="150"> </el-table-column> -->
          <!-- <el-table-column
            prop="cameraType"
            align="left"
            show-overflow-tooltip
            label="摄像头类型"
            :formatter="formatter"
          >
          </el-table-column> -->
          <el-table-column prop="vendor" align="left" show-overflow-tooltip label="摄像头品牌" width="80"></el-table-column>
          <el-table-column prop="cameraModel" align="left" show-overflow-tooltip label="摄像头型号" width="80"></el-table-column>
          <el-table-column prop="macId" align="left" label="MAC-ID" width="100"></el-table-column>
          <el-table-column prop="ip" align="left" label="IP地址" width="90"></el-table-column>
          <el-table-column width="100px" label="所属楼层/区域">
            <template slot-scope="scope">
              <el-select v-model="scope.row.floorNum" style="width: 90px" @change="handleFloorChange(scope.row)">
                <el-option
                  v-for="item in floorOptions"
                  :label="item.label"
                  :value="item.value"
                  :key="item.value"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="liveVideoOnly" align="center" label="仅播放视频" width="120">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.liveVideoOnly"
                style="width: 110px"
                @change="handleVideoOnlyChange(scope.row)"
              >
                <el-option label="仅播放视频" :value="1" :key="1"></el-option>
                <el-option label="否" :value="0" :key="0"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="bindTime" align="left" show-overflow-tooltip label="绑定时间" width="100"></el-table-column>
          <el-table-column prop="snapshotUrl" align="left" label="截图" width="80">
            <template slot-scope="scope">
              <img :src="scope.row.snapshotUrl" @click="enlargeImg(scope.row.snapshotUrl)" class="screenshot" />
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" align="center" width="200">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.streamSource !== 'NVR'"
                @click="linkSetting(scope.row)"
                type="text"
                size="small"
              >
                设置
              </el-button>
              <el-button @click="linklive(scope.row)" type="text" size="small">直播</el-button>
              <el-button @click="unbind(scope.row)" class="unbind" type="text" size="small">解绑</el-button>
              <el-button
                v-if="scope.row.streamSource !== 'NVR'"
                @click="change(scope.row)"
                class="unbind"
                type="text"
                size="small"
              >
                更换
              </el-button>
              <el-button
                v-if="scope.row.streamSource !== 'NVR'"
                type="text"
                size="small"
                class="unbind"
                @click="toDeviceInfo(scope.row)"
              >
                设备信息查询
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-button class="newDevice" type="primary" @click="getCameraList(true)">扫描新的设备</el-button>
        <div class="title">请选择摄像头进行绑定</div>
        <el-input
          v-model.trim="wordString"
          class="word-string"
          placeholder="请输入摄像头MacID、绑定盒子MacID检索"
        ></el-input>
        <el-table
          :data="nobindtable"
          class="nobindtable"
          v-loading="unbindLoading"
          @selection-change="handleSelectionChange"
          ref="multipleTable"
          :header-cell-class-name="tableClass"
          show-overflow-tooltip
        >
          <el-table-column type="selection" :selectable="selectInit" width="55"></el-table-column>
          <el-table-column type="index" align="right" show-overflow-tooltip label="序号" width="48"></el-table-column>
          <el-table-column prop="macId" align="right" show-overflow-tooltip label="摄像头MacID" width="150"></el-table-column>
          <el-table-column prop="cameraNo" align="center" label="状态" width="90">
            <template slot-scope="scope">
              {{ scope.row.online === true ? '在线' : '离线' }}
            </template>
          </el-table-column>
          <el-table-column prop="cameraNo" align="center" label="硬编解码" width="100">
            <template slot-scope="scope">
              {{ scope.row.accelerateHardware === true ? '开启' : '关闭' }}
            </template>
          </el-table-column>
          <el-table-column prop="cameraNo" align="center" label="码率" width="100">
            <template slot-scope="scope">
              {{ scope.row.accelerateHardware && scope.row.hardVideo ? computedDataRate(scope.row.hardVideo?.dataRateUpperLimit) : '--' }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="productId" align="center" label="SolutionID" width="150"> </el-table-column> -->
          <!-- <el-table-column
            prop="cameraType"
            align="right"
            show-overflow-tooltip
            label="摄像头类型"
            :formatter="formatter"
          >
          </el-table-column> -->
          <el-table-column prop="vendor" align="right" show-overflow-tooltip label="摄像头品牌" width="80"></el-table-column>
          <el-table-column prop="cameraModel" align="right" show-overflow-tooltip label="摄像头型号"></el-table-column>
          <el-table-column prop="ip" align="right" show-overflow-tooltip label="IP地址"></el-table-column>
          <el-table-column prop="bindStatus" align="center" label="绑定状态" width="130">
            <template slot-scope="scope">
              <span type="text" size="small">{{ scope.row.bindStatus ? '已绑定' : '未绑定' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="boxMac" label="绑定盒子MacID">
            <template slot-scope="scope">
              {{ scope.row.boxMac || '--' }}
            </template>
          </el-table-column>
          <el-table-column prop="	snapshotUrl" align="center" label="截图">
            <template slot-scope="scope">
              <img :src="scope.row.snapshotUrl" @click="enlargeImg(scope.row.snapshotUrl)" class="screenshot" />
            </template>
          </el-table-column>
        </el-table>
        <div class="allSelect">
          <div>
            <el-checkbox
              v-model="select"
              :indeterminate="isIndeterminate"
              @change="toggleSelection(nobindtable)"
              v-show="nobindtable.length"
            >
              全选
            </el-checkbox>
            <el-button @click="bindBox" v-show="nobindtable.length">绑定</el-button>
          </div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[5, 10, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next"
            :total="total"
          ></el-pagination>
        </div>
        <el-button @click="back">上一步</el-button>
        <el-button type="primary" @click="next">完成</el-button>
        <el-dialog :visible.sync="imgDialog" class="imgDialog" width="800px" title="预览" :before-close="handleClose">
          <img :src="bigImgUrl" class="bigImgUrl" />
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { upgrade, getCameraByBox } from 'src/api/legacy'
import { getNewestToken } from 'src/api/legacy'
import { apiv3 } from 'src/api/product'
import cameraMixin from '../cameraMixin'
import { computedDataRate } from 'src/utils/common'

export default {
  mixins: [cameraMixin],
  data() {
    return {
      computedDataRate,
      id: '',
      productId: '',
      cname: '',
      shopName: '',
      boxPort: '',
      boxMacId: '',
      boxNo: '',
      bindTableData: [],
      newTable: [],
      multipleSelection: [],
      pkid: '',
      status: '',
      select: false,
      imgDialog: false,
      bigImgUrl: '',
      currentPage: 1,
      pageSize: 5,
      total: 0,
      wordString: '',
      isIndeterminate: false,
      bindLoading: false,
      unbindLoading: false,
    }
  },
  watch: {
    wordString(val) {
      this.currentPage = 1
      const arrData = this.newTable.filter(
        (e) => e.macId.includes(this.wordString) || e.boxMAC.includes(this.wordString)
      )
      this.total = arrData.length
    },
  },
  computed: {
    nobindtable() {
      return this.newTable
        .filter((e) => e.macId.includes(this.wordString) || e.boxMAC.includes(this.wordString))
        .slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    },
  },
  mounted() {
    this.id = this.$route.query.boxId
    this.productId = this.$route.query.productId
    this.overviewInit()
  },
  methods: {
    handleVideoOnlyChange(row) {
      this.$confirm('确定修改吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          this.UpdateLiveVideoOnly(row)
        })
        .catch(() => {
          this.getCameraList()
        })
    },
    handleFloorChange(row) {
      const floorNum = this.floorOptions.find((e) => e.value === row.floorNum).label
      const message = '摄像头所属楼层/区域修改为：' + floorNum
      this.$confirm(message, '确定修改吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          this.updateFloorNum(row)
        })
        .catch(() => {
          this.getCameraList()
        })
    },
    formatter(row) {
      const obj = {
        VISIBLE_LIGHT: '可见光',
        INFRARED: '红外',
        UNKNOWN: '未知',
      }
      return obj[row.cameraType]
    },
    indexTable(index) {
      if (typeof index === 'undefined') {
        return 0
      }
      return index + 1
    },
    noindexTable(index) {
      if (typeof index === 'undefined') return
      return index + 1
    },
    nobindtableClass({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1) {
        return 'hideLabel'
      }
    },
    tableClass({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return 'hideLabel'
      }
    },
    selectInit(row) {
      return row.canBeCheck
    },
    handleSizeChange(val) {
      this.pageSize = val
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    mysort(a, b) {
      //canBeCheck 都为true
      if (a.canBeCheck && b.canBeCheck) {
        return a.cameraid > b.cameraid ? -1 : 1
      }
      //canBeCheck 都为false
      if (!a.canBeCheck && !b.canBeCheck) {
        return a.cameraid > b.cameraid ? -1 : 1
      }
      //canBeCheck 不同时为true
      else {
        return a.canBeCheck > b.canBeCheck ? 1 : -1
      }
    },
    getCameraList(msg) {
      this.bindLoading = true
      this.unbindLoading = true
      getCameraByBox({
        boxId: this.id,
        productId: this.productId,
      })
        .then((res) => {
          console.log(res)
          if (res && res.status === 0) {
            this.productId = res.data.productId
            this.cname = res.data.cname
            this.shopName = res.data.shopName
            this.boxPort = res.data.boxPort
            this.boxMacId = res.data.macId
            this.boxNo = res.data.boxNo
            this.status = res.data.onlineStatus ? '在线' : '离线'
            const hasBind = res.data.hasBind ? res.data.hasBind : []
            for (const v of hasBind) {
              v.boxMAC = v.boxMAC || ''
            }
            this.bindTableData = hasBind
            this.newTable = res.data.noBind.sort(this.mysort)
            for (const v of this.newTable) {
              v.boxMAC = v.boxMAC || ''
            }
            this.pkid = res.data.pkid
            this.total = this.newTable.length
            if (!msg) return
            this.$message({
              message: '扫描成功',
              type: 'success',
            })
          }
        })
        .finally(() => {
          this.bindLoading = false
          this.unbindLoading = false
        })
    },
    bindCamera() {
      let data = {
        pkid: this.id,
        cameraid: this.multipleSelection.map((v) => v.macId).join(),
        productId: this.productId,
      }

      apiv3.bindCamera(data).then((res) => {
        if (res && res.status === 0) {
          this.$alert(res.data.join(), '绑定提示', {
            confirmButtonText: '确定',
          })
          this.currentPage = 1
          this.getCameraList()
        }
      })
    },
    rowSelection(rows) {
      rows.forEach((row) => {
        if (row.canBeCheck) {
          this.$refs.multipleTable.toggleRowSelection(row)
        }
      })
    },
    toggleSelection(rows) {
      if (this.multipleSelection.length && this.isIndeterminate) {
        this.$refs.multipleTable.clearSelection()
        setTimeout(() => {
          this.rowSelection(rows)
          this.select = true
        }, 100)
      } else {
        this.rowSelection(rows)
        this.select = this.multipleSelection.length > 0
      }
    },
    handleSelectionChange(val) {
      const avaliTable = this.nobindtable.filter((e) => e.canBeCheck===true)
      //选中一条
      this.multipleSelection = val
      this.isIndeterminate = val.length > 0 && val.length < avaliTable.length
      if (
        this.multipleSelection?.length === avaliTable?.length
      ) {
        console.log(8888,this.multipleSelection,'====',avaliTable)
        this.select = true
      } else {
        this.select = false
      }
    },
    bindBox() {
      if (!this.multipleSelection.length) {
        this.$alert('请先选择要绑定的摄像头', '绑定', {
          confirmButtonText: '确定',
          type: 'warning',
        })
        return
      }
      if (this.bindTableData.length + this.multipleSelection.length > 1000) {
        this.$alert('摄像头绑定数量已达上限', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
        })
        return
      }
      this.$confirm('确定要绑定到该盒子上吗?', '绑定', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.bindCamera()
      })
    },
    overviewInit() {
      //页面初始化
      this.getCameraList()
    },
    back() {
      this.$router.go(-1)
    },
    next() {
      this.$router.go(-1)
    },
    linklive(row) {
      const { path } = this.$route
      const query = {
        cameraid: row.cameraId,
      }

      if (/^\/productManage/.test(path)) {
        this.$router.push({
          name: this.$route.name + '-live',
          query,
        })
      } else {
        this.$router.push({
          path: '/box/query/live',
          query,
        })
      }
    },
    linkSetting(row) {
      const { path } = this.$route
      const query = {
        cameraid: row.cameraId,
      }

      if (/^\/productManage/.test(path)) {
        this.$router.push({
          name: this.$route.name + '-setting',
          query,
        })
      } else {
        this.$router.push({
          path: '/box/query/setting',
          query,
        })
      }
    },
    change(row) {
      const { path } = this.$route
      const query = {
        cameraId: row.cameraId,
        productId: this.productId,
      }

      if (/^\/productManage/.test(path)) {
        this.$router.push({
          name: this.$route.name + '-update',
          query,
        })
      } else {
        this.$router.push({
          path: '/box/query/change',
          query,
        })
      }
    },
    unbind(row) {
      console.log(row)
      this.$confirm(`是否解绑${row.cameraId}摄像头`, '提示').then(() => {
        this.unbindCamera(row)
      })
    },
    unbindCamera(row) {
      let data = {
        pkid: this.id,
        cameraid: row.cameraId,
      }
      upgrade.unbindCamera(data).then((res) => {
        if (res && res.status === 0) {
          this.$alert(res.data.join(), '解绑提示', {
            confirmButtonText: '确定',
          })
          this.getCameraList()
        }
      })
    },
    handleClose(done) {
      done()
    },
    enlargeImg(url) {
      this.imgDialog = true
      this.bigImgUrl = url
    },
    // 跳转到设备信息页
    async toDeviceInfo(row) {
      let href = process.env.VUE_APP_DEVICE_APP
      const username = JSON.parse(localStorage.getItem('user'))[0].username
      // const accessToken = localStorage.getItem('accessToken')
      const { data: accessToken } = await getNewestToken({})
      window.open(
        href + '?accessToken=' + accessToken + '&cameraId=' + row.cameraId + '&username=' + username + '&boxType=0'
      )
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/styles/variables.less';

* {
  box-sizing: border-box;
}

.containerX .contentX {
  padding-left: 0;
  padding-right: 0;
}

.titletop {
  width: 100%;
  height: 80px;
  line-height: 80px;
  border-bottom: 1px solid #d1d1d1;
  padding: 0px 20px;
}

.titletop span {
  font-size: 14px;
  color: #5c76cc;
}

.titletop span i {
  color: #333;
}

.content {
  padding: 0 20px 40px;
}
.content .detail {
  padding: 10px 0;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d1d1d1;
  flex-wrap: wrap;
}
.content .detail .box {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 8px;
}
/* .content .detail div .label{
  float:left;
}
.content .detail  .box:nth-child(1) .text,
.content .detail  .box:nth-child(2) .text
{
  margin-left: 70px;
}
.content .detail  .box:nth-child(3) .text{
  margin-left: 56px;
}
.content .detail  .box:nth-child(4) .text{
  margin-left: 90px;
} */
.content .title {
  font-size: 18px;
  margin-top: 20px;
}

.content .title span {
  color: #5c76cc;
}

.bindtable,
.nobindtable,
.allSelect {
  margin-bottom: 20px;
}
.allSelect {
  display: flex;
  justify-content: space-between;
}
.allSelect .el-checkbox {
  margin-right: 20px;
}

.el-button {
  font-size: 12px;
}

.hideLabel .el-checkbox,
.hideLabel div {
  display: none !important;
}
.newDevice {
  margin-top: 20px;
}
.unbind::before {
  content: '';
  height: 5px;
  border-left: 1px solid @linkColor;
  margin-right: 12px;
}

.screenshot {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.imgDialog .el-dialog__header {
  padding: 0;
  border: none;
  height: 0px;
}

/* 关闭按钮 */
.imgDialog .el-dialog__headerbtn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #c2c2c2;
  right: 10px;
  top: 10px;
}

/* 关闭图标 */
.imgDialog .el-dialog__headerbtn .el-icon-close {
  color: #333;
}

.imgDialog .el-dialog__body {
  padding: 0;
}

.imgDialog .bigImgUrl {
  width: 760px;
  height: 600px;
  vertical-align: top;
}
.el-message-box__content {
  padding-left: 36px;
}

.word-string {
  width: 300px;
}
</style>
